@import '../variables.css';

.rosterAdd {
  text-align: right;
}
.rosterAdd .icon.mod-plus {
  width: 11px;
  height: 11px;
  fill: #fff;
}

.rosterAdd .button.mod-add {
  font-size: 1rem;
}

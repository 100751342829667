@import '../variables.css';

.roster {
  width: 100%;
}

.roster,
.roster td {
  border: 0 none;
  border-collapse: collapse;
}

.roster tr:not(:first-child) td {
  padding-bottom: 0.4rem;
}

.roster tr:first-child td {
  padding-bottom: 0.6rem;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: uppercase;
}

.roster td {
  padding: 0;
  vertical-align: top;
}

.roster td:not(:last-child) {
  padding-right: 1rem;
}

.roster .roster-cell-preview,
.roster .roster-cell-remove {
  width: var(--width-normal--rosterActionButton);
}

.roster:not(.mod-twoSizes) .roster-cell-size {
  width: 9.3rem;
}

.roster.mod-twoSizes .roster-cell-size {
  width: 6rem;
}

.roster .roster-cell-quantity {
  width: 5.5rem;
}

.roster .roster-cell-number {
  width: 5rem;
}

.roster-row.mod-alignRight td {
  text-align: right;
}

.roster .roster-cell-remove {
  text-align: right;
}

.roster .roster-cell-remove .button {
  font-size: 1rem;
}

.roster .roster-cell-remove .icon {
  width: 16px;
  height: 16px;
}

.roster-agreement-label {
  display: flex;
  align-items: center;
  margin: 16px 0 0 0;
  font-size: 12px;
}
